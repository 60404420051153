<template>
  <div
    class="program-card"
    @click="goToDetail(upComingProgram['s.no.'])"
    :key="upComingProgram['s.no.']"
    @mouseover="$emit('pauseInterval')"
    @mouseout="$emit('resumeInterval')"
  >
    <div class="program-card__left" v-if="showThumb">
      <img :src="upComingProgram.imageid3after" alt="" />
    </div>
    <div
      :class="showThumb ? 'program-card__right' : 'program-card--nothumbnail'"
    >
      <div class="program-card__head">
        <h2 class="program-title">{{ upComingProgram.title }}</h2>
        <div class="program-duration">
          कार्यक्रम को अवधि
          <span
            >{{
              englishToNepaliNumber(
                upComingProgram.endyear - upComingProgram.startyear
              )
            }}
            वर्ष</span
          >
        </div>
      </div>
      <div class="program-card__body">
        <div class="program-date">
          <div class="program-date__start">
            <unicon name="calendar-alt" /> <span>शुरु भएको वर्ष</span>
            <span>{{ englishToNepaliNumber(upComingProgram.startyear) }}</span>
          </div>
          <div class="program-date__end">
            <unicon name="calendar-alt" /> <span>अन्त्य भएको वर्ष</span>
            <span>{{ englishToNepaliNumber(upComingProgram.endyear) }}</span>
          </div>
        </div>
        <!-- <div class="program-budget-progress">
            <progress-bar
              :progress="(upComingProgram.expenditure / upComingProgram.budget) * 100"
            ></progress-bar>
          </div> -->
        <div class="program-budget">
          कुल लागत
          <div class="program-budget__expenditure">
            रु. {{ formattedEstimatedBudget }}
          </div>
        </div>
      </div>
      <div class="program-card__action" v-if="showActionLink">
        <a @click.prevent="moreDetail(upComingProgram['s.no.'])"
          >थप जानकारी <unicon name="sign-out-alt"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { getFormatedNepaliNumber } from "../../../helper/helper";
import { englishToNepaliNumber } from "nepali-number";
export default {
  name: "UpcomingProgram",
  components: {
    // eslint-disable-next-line
    progressBar: () => import("@/components/ProgressBar.vue"),
  },
  computed: {
    formattedEstimatedBudget() {
      let estimatedBudget = this.upComingProgram.estimatebudget;
      estimatedBudget = parseFloat(estimatedBudget.replace(/,/g, ""));
      return getFormatedNepaliNumber(estimatedBudget);
    }
  },
  props: {
    upComingProgram: { type: Object, required: true },
    showThumb: { type: Boolean, default: false, required: false },
    showActionLink: { type: Boolean, default: true, required: false }
  },
  methods: {
    englishToNepaliNumber,
    goToDetail(id) {
      this.$emit("upComingProgramCardClickHandler", id);
    },
    moreDetail(id) {
      this.$emit("moreDetailClickHandler", id);
    },
    getFormatedNepaliNumber
  }
};
</script>

<style lang="scss" scoped>
</style>
